import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./NotificationDataTable.css";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";
import _ from 'lodash';

const NotificationDataTable = ({
  notification,
  deleteNotification
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };
console.log(notification,'not');
  const deleteNotificationHandler = () => {
    deleteNotification(notification.objectId);
  }

  // const deleteMemoryPersonHandler = () => {
  //   deleteInMemoryPerson(person.objectId);
  // };

  // useEffect(() => {
  //   setIsPersonApproved(person.Approved);
  // }, []);

  return (
    <React.Fragment>
      <tr>
        <td>{notification.notificationText}</td>
        <td>{notification.notificationTextInAR}</td>

        <td>
          <Link to={`/editNotification/${notification.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${notification.notificationText}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteNotificationHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default NotificationDataTable;
