import React from "react";
import { connect } from "react-redux";
import { toggleMobileNavVisibility } from "../../reducers/Layout";
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

const Header = ({ showMobileMenu, toggleMobileNavVisibility }) => {
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload(false);
  };
  return (
    <div style={{  display: "flex",
    justifyContent: "end",
    alignItems: "center",
    width: "100%",
    padding: " 20px",}}>
      <Link to="/" onClick={handleLogout}>
        Log out <i className="fa fa-sign-out logoutIcon"></i>
      </Link>
    </div>
  );
};

const mapStateToProp = (state) => ({});

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

export default connect(mapStateToProp, mapDispatchToProp)(Header);
