import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./TestimonialsDataTable.css";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";
import _ from 'lodash';

const TestimonialsDataTable = ({
  testimonial,
  deleteTestimonial
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteTestimonialHandler = () => {
    deleteTestimonial(testimonial.objectId);
  }

  return (
    <React.Fragment>
      <tr>
        <td>
          <img
            className="testimonial-img"
            src={testimonial.image}
            alt={`${testimonial.customerName}`}
          />
        </td>
        <td>{testimonial.customerName}
        </td>
        <td>{testimonial.customerNameAR}
        </td>
        <td>{testimonial.reviewContent}
        </td>
        <td>{testimonial.reviewContentAR}
        </td>
        <td>
          <Link to={`/editTestimonial/${testimonial.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${testimonial.name}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteTestimonialHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default TestimonialsDataTable;
