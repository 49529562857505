import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const url = `notification`;

const defaultState = {
  notifications: [],
  notification: {},
  error: null,
  isPending: null,
  loaded: false,
};

const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const NOTIFICATIONS_PENDING = "NOTIFICATIONS_PENDING";
const NOTIFICATION_PENDING = "NOTIFICATION_PENDING";
const GET_NOTIFICATION = "GET_NOTIFICATION";
const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";


// GET Notification
export const getNotifications = () => async (dispatch, getState) => {
  dispatch({
    type: NOTIFICATIONS_PENDING ,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    console.log(getState().Notifications);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_ERROR,
      payload: error,
    });
  }
};

// GET Notification

export const getNotification = (personId) => async (dispatch, getState) => {
  dispatch({
    type: NOTIFICATION_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${personId}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_NOTIFICATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: error,
    });
  }
};


// Create notification
export const createNotification = (newNotificationData) => async (dispatch, getState) => {
  dispatch({
    type: NOTIFICATIONS_PENDING ,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newNotificationData,
      getConfigHeaders()
    );
    console.log(getState().Notifications);
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_ERROR,
      payload: error,
    });
  }
};

export const editNotification = (id, newNotificationsData) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newNotificationsData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_NOTIFICATION,
      payload: { id, newNotificationsData },
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: error,
    });
  }
}

// Delete notifications Person
export const deleteNotification = (id) => async (dispatch, getState) => {
  dispatch({
    type: NOTIFICATIONS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_NOTIFICATION,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case NOTIFICATION_PENDING:
    case NOTIFICATIONS_PENDING:
      return { ...state, isPending: true };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_NOTIFICATION:
      return {
        notifications: [...state.notifications, action.payload],
        isPending: false,
        loaded: true
      }

    case EDIT_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((memory) =>
         (memory.objectId === action.payload.id
          ? action.payload.newNotificationsData : memory)),
        isPending: false,
        loaded: true
      };

    case GET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
        loaded: true,
        isPending: false
      };

    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (person) => person.objectId !== action.payload
        ),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
