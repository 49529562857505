import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ConfirmModal = ({ message, children, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Appgain</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
        {children}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
