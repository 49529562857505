import React from "react";


const Dashboard = () => (
  <div className="content welcome-content">
    <h2>Welcome to Nabolia Dashboard</h2>

  </div>
);

export default Dashboard;
