import "./AddGallery.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";

import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
  getTeam,
  createTeam,
  editTeam,
  uploadTeamLogo,
} from "../../reducers/Teams";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import _ from "lodash";
import { MenuItem, Select } from "@material-ui/core";
const AddTeam = ({
  history,
  isPending,
  team,
  getTeam,
  createTeam,
  editTeam,
  uploadLogo,
  uploadTeamLogo,
  teamLogo,
}) => {
  const teamId =
    window.location.hash.includes("editGallery") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("galleryId: ", teamId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [show, setShow] = useState(false);


  const [openType, setOpenType] = useState(false);
  const [type, setType] = useState('chair');
  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [teamId]);

  const cancel = () => {
    history.go(-1);
  };

  useEffect(() => {
    // if (selectedLogo) {
    //   if (selectedLogo.size > 512000) {
    //     setShow(true);
    //     selectedLogo("");
    //   }
    // }
    //selectedLogo,
    if (teamLogo) {
      console.log("galleryLogo: ", teamLogo);
      setSelectedLogoUrl(teamLogo);
    }
  }, [ teamLogo]);
const handleTypeChange=e=>{
  setType(e.target.value)
}
  const storeImageHandler = e => {
    
    e.preventDefault();
    setSelectedLogo(e.target.files[0]);
    console.log(e.target.files[0],'selectedLogo');
  };

  const submitTeamHandler = e => {
    e.preventDefault();
    const teamData = {
      Name: name,
      Title: title,
      Logo: selectedLogoUrl,
      Type: type,
    };
    console.log(teamData);

    if (isEditingMode) {
      editTeam(teamId, teamData);
    } else {
      createTeam(teamData);
    }
    // wipeAmbassadorImageData();
    history.push("/gallery");
  };

  useEffect(() => {
    if (!!team && teamId) {
      setName(team.Name);

      setTitle(team.Title);
      setSelectedLogoUrl(team.Logo);
      setEdit(true);
    }
  }, [team]);

  // const uploadLogoHandler = () => {
  //   uploadLogo(selectedLogo, name);
  //   setEdit(true);
  // };

  const uploadLogoHandler = () => {
    const fd = new FormData();
    fd.append("photo", selectedLogo);
    fd.append("skipValidation",true)
    uploadTeamLogo(fd);
    // uploadTestimonialImage(selectedImage);
    console.log('uploadImageHandler');
    setEdit(true);
};

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className='title'>
        {isEditingMode ? `Edit Items: ${name}` : "Add New Items"}
      </h2>
      <form
        className='container'
        style={{ minHeight: "auto" }}
        onSubmit={submitTeamHandler}
      >
        <FormGroup>
          <div className='fieldDiv'>
            <label id='teamName'>Name</label>
            <FormControl
              type='text'
              value={name}
              onChange={e => setName(e.target.value)}
              name='teamName'
              htmlFor='teamName'
              placeholder='Enter Name'
              required
            />
          </div>
          <div className="fieldDiv">
              <label id="type">type</label>
              <Select
                labelId="type"
                id="type"
                open={openType}
                onClose={() => setOpenType(false)}
                onOpen={() => setOpenType(true)}
                value={type}
                onChange={handleTypeChange}
                style={{ padding: '5px 10px' }}>
                <MenuItem value="chair">
                  chair
                </MenuItem>
                <MenuItem value="couch">
                Couch
                </MenuItem>
              </Select>
            </div>
          <div className='fieldDiv'>
            <label id='title'>Title</label>
            <FormControl
              type='text'
              value={title}
              onChange={e => setTitle(e.target.value)}
              name='teamTitle'
              htmlFor='teamTitle'
              placeholder='Enter Title'
              required
            />
          </div>

          <div className='fieldDiv'>
            <label>
              {isEditingMode ? "Change" : "Upload"} Photo
            </label>
            <div className='file-input'>
              {/* SVG support */}
              <input
                type='file'
                accept='image/*'
                placeholder='Choose Team Logo'
                onChange={storeImageHandler}
                className='file'
                id='file'
              />
              <label htmlFor='file'>
                {selectedLogo || isEditingMode ? "Change " : "Select "} Photo
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedLogo && (
                <React.Fragment>
                  <p>Please Upload the photo</p>
                  <button
                    onClick={uploadLogoHandler}
                    className='btn btn-success w-100 my-3'
                  >
                    Upload photo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className='btns'>
          <Button
            disabled={!edit}
            className='submitBtn'
            type='submit'
            bsStyle='primary'
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className='CancelBtn' bsStyle='primary' onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className='error'>
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  isPending: state.Teams.isPending,
  team: state.Teams.team,
  teamLogo: state.Teams.teamLogo,
});

const actions = dispatch => ({
  getTeam: teamId => dispatch(getTeam(teamId)),
  createTeam: newTeamData => dispatch(createTeam(newTeamData)),
  editTeam: (teamId, newTeamData) => dispatch(editTeam(teamId, newTeamData)),
  uploadTeamLogo: (image) => dispatch(uploadTeamLogo(image)),
});

export default connect(mapStateToProps, actions)(AddTeam);
