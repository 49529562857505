import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <i className="fa fa-spinner" aria-hidden="true" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
