import './AddTestimonial.css';
import {
    FormControl,
    FormGroup,
    Button,
    ToggleButton,
} from "react-bootstrap";

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
    getTestimonial,
    createTestimonial,
    editTestimonial,
    uploadTestimonialImage
} from "../../reducers/Testimonials";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from 'lodash';
const AddTestimonial = ({
    history,
    isPending,
    testimonial,
    getTestimonial,
    createTestimonial,
    editTestimonial,
    // uploadImage,
    uploadTestimonialImage,
    testimonialImage

}) => {
    const testimonialId =
        window.location.hash.includes("editTestimonial") &&
        window.location.hash.split("/")
        [window.location.hash.split("/").length - 1];
    console.log('testimonialId: ', testimonialId);
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerNameAR, setCustomerNameAR] = useState('');

    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [services, setServices] = useState('');
    const [image, setImage] = useState('');
    const [reviewContent, setReviewContent] = useState('');
    
    const [reviewContentAR, setReviewContentAR] = useState('');

    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [show, setShow] = useState(false);


    useEffect(() => {
        if (testimonialId) {
            getTestimonial(testimonialId);
            setIsEditingMode(true);
        } else {
            setIsEditingMode(false);
        }
    }, [
        testimonialId
    ]);

    const cancel = () => {
        history.go(-1);
    }

    useEffect(() => {
        // if (selectedImage) {
        //     if (selectedImage.size > 512000) {
        //         setShow(true);
        //         selectedImage("");
        //     }
        // }
        if (testimonialImage) {
            console.log('testimonialImage: ', testimonialImage);
            setSelectedImageUrl(testimonialImage);
        }
    }, [selectedImage, testimonialImage]);


    const storeImageHandler = (e) => {
        e.preventDefault();
        setSelectedImage(e.target.files[0]);
    };



    const submitTestimonialHandler = (e) => {
        e.preventDefault();
        const testimonialData = {
            name,
            customerName,
            customerNameAR,
            country,
            reviewContent: reviewContent,
            reviewContentAR:reviewContentAR,
            image: selectedImageUrl
        };

        if (isEditingMode) {
            editTestimonial(testimonialId,
                 testimonialData);
                 console.log(testimonialData,'edit data');
        } else {
            createTestimonial(testimonialData);
            console.log(testimonialData,'create data');

        }

        uploadTestimonialImage(selectedImage);
        console.log(selectedImage);

        // wipeAmbassadorImageData();
        history.push("/Testimonials");
    };

    useEffect(() => {
        if (!!testimonial && testimonialId) {
            setCustomerName(testimonial.customerName);
            setCustomerNameAR(testimonial.customerNameAR);

            setReviewContent(testimonial.reviewContent);
            setReviewContentAR(testimonial.reviewContentAR);

            setSelectedImageUrl(testimonial.image);
            setEdit(true)


        }
    }, [testimonial]);

    const uploadImageHandler = () => {
        const fd = new FormData();
        fd.append("photo", selectedImage);
        fd.append("skipValidation",true)
        uploadTestimonialImage(fd);
        // uploadTestimonialImage(selectedImage);
        console.log('uploadImageHandler');
        setEdit(true);
    };

    if (isPending) return <LoadingSpinner />

    return (
        <div>
            <h2 className="title">
                {isEditingMode
                    ? `Edit Testimonial: ${name}`
                    : "Add New Testimonial"}
            </h2>
            <form
                className="container"
                style={{ minHeight: "auto" }}
                onSubmit={submitTestimonialHandler}
            >
                <FormGroup>

                    <div className="fieldDiv">
                        <label id="testimonialClientName">
                            customer Name In EN
                        </label>
                        <FormControl
                            type="text"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            name="testimonialClientName"
                            htmlFor="testimonialClientName"
                            placeholder="Enter Name"
                            required
                        />
                    </div>

                    <div className="fieldDiv">
                        <label id="testimonialClientNameAR">
                            customer Name In AR
                        </label>
                        <FormControl
                            type="text"
                            value={customerNameAR}
                            onChange={(e) => setCustomerNameAR(e.target.value)}
                            name="testimonialClientNameAR"
                            htmlFor="testimonialClientNameAR"
                            placeholder="Enter Name"
                            required
                        />
                    </div>

                    {/* <div className="fieldDiv">
                        <label id="testimonialName">Name</label>
                        <FormControl
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="testimonialName"
                            htmlFor="testimonialName"
                            placeholder="Enter Name"
                            required
                        />
                    </div> */}

                    {/* <div className="fieldDiv">
                        <label id="testimonialCountry">Testimonial Country</label>
                        <FormControl
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            name="testimonialCountry"
                            htmlFor="testimonialCountry"
                            placeholder="Enter Country"
                            required
                        />
                    </div> */}

                    <div className="fieldDiv">
                        <label id="testimonialDescription">Review Content In EN</label>
                        <FormControl
                            type="text"
                            value={reviewContent}
                            onChange={(e) => setReviewContent(e.target.value)}
                            name="testimonialDescription"
                            htmlFor="testimonialDescription"
                            placeholder="Enter Review"
                            required
                        />
                    </div>

                    <div className="fieldDiv">
                        <label id="testimonialDescriptionAR">Review Content In AR</label>
                        <FormControl
                            type="text"
                            value={reviewContentAR}
                            onChange={(e) => setReviewContentAR(e.target.value)}
                            name="testimonialDescriptionAR"
                            htmlFor="testimonialDescriptionAR"
                            placeholder="Enter Review"
                            required
                        />
                    </div> 

                    <div className="fieldDiv">
                        <label>
                            {isEditingMode ? "Change" : "Upload"} Testimonial Image
                        </label>
                        <div className="file-input">
                            {/* SVG support */}
                            <input
                                type="file"
                                accept="image/*"
                                placeholder="Choose Testimonial Image"
                                onChange={storeImageHandler}
                                className="file"
                                id="file"
                            />
                            <label htmlFor="file">
                                {selectedImage || isEditingMode ? "Change " : "Select "} Image
                            </label>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {selectedImage && (
                                <React.Fragment>
                                    <p>Please Upload the image</p>
                                    <button
                                        onClick={uploadImageHandler}
                                        className="btn btn-success w-100 my-3"
                                    >
                                        Upload image
                                    </button>
                                </React.Fragment>
                            )}
                        </div>

                    </div>

                </FormGroup>
                <div className="btns">
                    <Button disabled={!edit} className="submitBtn" type="submit" bsStyle="primary">
                        {isEditingMode ? "Edit" : "Submit"}
                    </Button>
                    <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                {show && (
                    <ConfirmModal
                        handleClose={() => setShow(false)}
                        handleShow={() => setShow(true)}
                        message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
                        setShow={setShow}
                        show={show}
                    ></ConfirmModal>
                )}
                <div className="error">
                    <p>{error}</p>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    isPending: state.Testimonials.isPending,
    testimonial: state.Testimonials.testimonial,
    testimonialImage: state.Testimonials.testimonialImage
})

const actions = dispatch => ({
    getTestimonial: testimonialId => dispatch(getTestimonial(testimonialId)),
    createTestimonial: newTestimonialData => dispatch(createTestimonial(newTestimonialData)),
    editTestimonial: (testimonialId, newTestimonialData) => dispatch(editTestimonial(testimonialId, newTestimonialData)),
    uploadTestimonialImage: (image) => dispatch(uploadTestimonialImage(image))

})

export default connect(
    mapStateToProps,
    actions
)(AddTestimonial);
