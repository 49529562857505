import React, { Component, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import NotificationSystem from "react-notification-system";
import _ from "lodash";

const Nav = ({ location, updateProjects }) => {


  const useStyles = makeStyles(theme => ({
    updateProjectButton: {
      width: "100%",
      marginBottom: "1rem",
    },
    updateCasesButton: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  return (
    <ul className='nav'>
      <li className={location.pathname === "/gallery" ? "active" : null}>
        <Link to='/gallery'>
          <i className='fa fa-photo'></i>
          <p>Gallery</p>
        </Link>
      </li>
    

      <li className={location.pathname === "/selectedVideos" ? "active" : null}>
        <Link to='/selectedVideos'>
          <i className='fa fa-video-camera'></i>
          <p>Selected Videos</p>
        </Link>
      </li>
   

      <li className={location.pathname === "/testimonials" ? "active" : null}>
        <Link to='/testimonials'>
          <i className='fa fa-sticky-note-o'></i>
          <p>Testimonials</p>
        </Link>
      </li>
      <li className={location.pathname === "/notification" ? "active" : null}>
        <Link to='/notification'>
          <i className='fa fa-bell'></i>
          <p>Notification</p>
        </Link>
      </li>


    </ul>
  );
};

export default compose(
  withRouter,

)(Nav);
