import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import ThemeOptions from "./ThemeOptions";
import Layout from "./Layout";
import Auth from "./Auth";

import Demos from "./Demos";
import Testimonials from "./Testimonials";
import Teams from "./Teams";
import Notifications from "./Notifications";
export default {
  Auth,
  ThemeOptions,
  Layout,
  Demos,
  Testimonials,
  Teams,
  Notifications,
  form: formReducer.plugin({
    Case: (state, action) => {
      //      sms_payment[0].keyword_en
      switch (action.type) {
        case "@@redux-form/UNREGISTER_FIELD":
          if (!state.values) return state;
          const existingFields = Object.keys(state.registeredFields)
            .filter(k => k.includes("["))
            .map(k => ({
              name: k.split("[")[0],
              index: parseInt(k.split("[")[1].split("]")[0]),
            }));

          const valuesThatAreArrays = Object.keys(state.values).filter(k =>
            Array.isArray(state.values[k])
          );

          const values = state.values;
          valuesThatAreArrays.forEach(key => {
            const newArr = [];
            const arrIdxKeys = Object.keys(state.values[key]);
            console.log("arrIdxKeys: ", arrIdxKeys, state.values[key], key);
            arrIdxKeys.forEach(keyIdx => {
              const found = existingFields.find(field => {
                return field.name == key && field.index == keyIdx;
              });
              if (found) newArr.push(state.values[key][keyIdx]);
            });
            values[key] = newArr;
          });
          return {
            ...state,
            values,
          };
        default:
          return state;
      }
    },
    Project: (state, action) => {
      //      sms_payment[0].keyword_en
      switch (action.type) {
        case "@@redux-form/UNREGISTER_FIELD":
          if (!state.values) return state;
          const existingFields = Object.keys(state.registeredFields)
            .filter(k => k.includes("["))
            .map(k => ({
              name: k.split("[")[0],
              index: parseInt(k.split("[")[1].split("]")[0]),
            }));

          const valuesThatAreArrays = Object.keys(state.values).filter(k =>
            Array.isArray(state.values[k])
          );

          const values = state.values;
          valuesThatAreArrays.forEach(key => {
            const newArr = [];
            const arrIdxKeys = Object.keys(state.values[key]);
            console.log("arrIdxKeys: ", arrIdxKeys, state.values[key], key);
            arrIdxKeys.forEach(keyIdx => {
              const found = existingFields.find(field => {
                return field.name == key && field.index == keyIdx;
              });
              if (found) newArr.push(state.values[key][keyIdx]);
            });
            values[key] = newArr;
          });
          return {
            ...state,
            values,
          };
        default:
          return state;
      }
    },
  }),
};
