import React from "react";
import "./IconBtn.css";

const IconBtn = ({ title, onClick, btnBehaviourClass, iconName }, props) => {
  return (
    <button
      title={title}
      onClick={onClick}
      className={`icon-btn ${btnBehaviourClass}-btn`}
      {...props}
    >
      <i className={`fa fa-${iconName}`} style={{ fontSize: "20px" }}></i>
    </button>
  );
};

export default IconBtn;
