import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./DemosDataTable.css";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";
import _ from 'lodash';

const DemosDataTable = ({
  video,
  deleteDemo
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };
console.log(video);
  const deleteDemoHandler = () => {
    deleteDemo(video.objectId);
  }

  // const deleteMemoryPersonHandler = () => {
  //   deleteInMemoryPerson(person.objectId);
  // };

  // useEffect(() => {
  //   setIsPersonApproved(person.Approved);
  // }, []);

  return (
    <React.Fragment>
      <tr>
        <td>{video.videoLink}</td>
        <td>
          <Link to={`/editVideo/${video.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${video.videoLink}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteDemoHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default DemosDataTable;
