export const config = {
  isProd: process.env.NODE_ENV === "production",
  stgApiUrl: "https://stgapi.ikhair.net",
  //stgApiUrl: "http://localhost:9000",
  //stgApiUrl: 'https://api2.ikhair.net',
  ikhair: "https://api2.ikhair.net",
  ikhairstg: "https://stgapi.ikhair.net",
  kheerom: "https://api-om.ikhair.net",
  jonation: "https://jonationapi.ikhair.net",
  prodApibagisUrl: "https://bagisapi.instabackend.io",
  kongPassword: "n7s7id63",
};

export const getServerUrl = () =>
  // config[process.env.APP_NAME] //  uncomment to test in development
  config.isProd ? config[process.env.APP_NAME] : config.ikhairstg; // and comment this
