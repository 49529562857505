import './AddDemo.css';

import {
  FormControl,
  FormGroup,
  Button,
} from "react-bootstrap";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getDemo,
  editDemo,
  createDemo
} from "../../reducers/Demos.js";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from 'lodash';
const AddDemo = ({
  history,
  isPending,
  demo,
  getDemo,
  editDemo,
  createDemo,

}) => {
  const demoId =
    window.location.hash.includes("editVideo") &&
    window.location.hash.split("/")
    [window.location.hash.split("/").length - 1];
  console.log('videoId: ', demoId);
  console.log(typeof(demoId));
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');
console.log(demoId,'video');
  useEffect(() => {
    if (demoId) {
      getDemo(demoId);
      setIsEditingMode(true);
      console.log('true');
    } else {
      setIsEditingMode(false);
      console.log('false');
    }
  }, [
    demoId
  ]);

  const cancel = () => {
    history.go(-1);
  }

  const submitDemosPersonDataHandler = (e) => {
    e.preventDefault();
    const newDemoData = {
      videoLink,
    };

    console.log(newDemoData);

    if (isEditingMode) {
      editDemo(demoId, newDemoData);
    } else if (!isEditingMode && videoLink) {
      createDemo(newDemoData);
    } else {
      setError('url is required');
      return;
    }
    // wipeAmbassadorImageData();
    history.push("/selectedVideos");
  };

  useEffect(() => {
    if (!!demo && demoId) {
      setVideoLink(demo.videoLink);
      setEdit(true);
    }
  }, [demo]);


  if (isPending) return <LoadingSpinner />

  return (
    <div>
      <h2 className="title">
        {isEditingMode
          ? `Edit Video: ${videoLink}`
          : "Add New Video"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitDemosPersonDataHandler}
      >
        <FormGroup>

         
          <div className="fieldDiv">
            <label id="videoLink">Video Link</label>
            <FormControl
              type="text"
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
              name="videoLink"
              htmlFor="videoLink"
              placeholder="Enter Video Link"
              required
            />
          </div>

        



        </FormGroup>
        <div className="btns">
          <Button className="submitBtn" type="submit" bsStyle="primary">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  isPending: state.Demos.isPending,
  demo: state.Demos.demo
})

const actions = dispatch => ({
  getDemo: demoId => dispatch(getDemo(demoId)),
  editDemo: (demoId, newDemoData) => dispatch(editDemo(demoId, newDemoData)),
  createDemo: newDemoData => dispatch(createDemo(newDemoData))
})

export default connect(
  mapStateToProps,
  actions
)(AddDemo);
